<!--
// nuxt-ui/components/Shared/stats/GridFeaturedStatsHome.vue
-->
<script setup lang="ts">
import type { SeasonStatsPartner } from "~/src/season-cluster/domain/models/01.Season";
import type { RankingsData, TurnMvpPlayer } from "~/src/player/domain/Player";
import type { BannerCard } from "~/src/content/domain/models/banner";
import CardPlayerMvp from "~/nuxt-ui/components/pages/region-stats/CardPlayerMvp.vue";
import CardStoreAd from "~/nuxt-ui/components/Shared/CardStoreAd.vue";
import { usePlayers } from "~/nuxt-ui/Shared/composables/players";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useSeasons } from "~/nuxt-ui/Shared/composables/season/seasons";
import bannerUiService from "~/src/content/infrastructure/ui-services/bannerUiService";
import CardPlayerRankingGeneralNoPartner from "~/nuxt-ui/components/pages/region-stats/cards-featured-stats/CardPlayerRankingGeneralNoPartner.vue";
import CardPlayerRankingCards from "~/nuxt-ui/components/pages/region-stats/cards-featured-stats/CardPlayerRankingCards.vue";
import CardBestGoalkeeper from "~/nuxt-ui/components/pages/region-stats/cards-featured-stats/CardBestGoalkeeper.vue";

const { getLastTurnMvp } = usePlayers();
const { useFetchParamsSeasonStatsFirstPlayer } = useSeasons();
const { regionParam, isSpain, isWorldCup } = useRegions();
const { webContext } = useWebContext();
const { currentSeason } = useCompetitionStore();
const { getCardBanner } = bannerUiService();
const localePath = useLocalePath();

const mvpPartner: SeasonStatsPartner | undefined = currentSeason?.metaInformation?.stats_partner
  ? currentSeason?.metaInformation?.stats_partner
  : undefined;

const turnMvp = ref<TurnMvpPlayer | undefined>();
const topMvps = ref<RankingsData | undefined>();

const storeBanner = ref<BannerCard | undefined>();

if (isWorldCup) {
  const fetchOptsMvps = useFetchParamsSeasonStatsFirstPlayer(currentSeason.id, "MVP");
  if (fetchOptsMvps) {
    const { path: pathMvps, opts: optsMvps } = fetchOptsMvps;
    const { data } = await useFetch(pathMvps, optsMvps);
    topMvps.value = data.value;
  }
} else {
  turnMvp.value = currentSeason && currentSeason?.id !== 13 ? await getLastTurnMvp(currentSeason) : undefined;
}

onMounted(async () => {
  await nextTick();
  storeBanner.value = await getCardBanner("home");
});
</script>

<template>
  <div class="md:bg-gray-900 mt-8 md:p-4 rounded-lg lg:mt-0">
    <div class="flex text-gray-400 uppercase text-sm mb-2 justify-between items-end">
      <p>{{ $t("general.stats.title") }}</p>
      <nuxt-link
        :to="
          localePath({
            name: `region-Stats`,
            params: { region: regionParam },
          })
        "
        class="normal-case text-primary text-xs"
      >
        {{ $t("cards.stats.seeMore.link") }}
      </nuxt-link>
    </div>

    <hr />

    <div class="main-container-cards">
      <!--Mvp cards-->
      <card-player-mvp
        v-if="topMvps && topMvps.player && isWorldCup"
        :featured-player-image="topMvps.player.image?.url"
        :featured-player-name="topMvps.player.shortName"
        :featured-player-team-image="topMvps.team.logo?.url"
        :featured-player-stat="topMvps.total"
        :turn-number="topMvps.total"
        :has-partner="!!mvpPartner"
        :partner-logo="mvpPartner?.logo"
        :partner-name="mvpPartner?.name"
        :table-stat-title="$t('general.stats.mvps')"
        page-stats-link="Mvp"
        hide-turn
      ></card-player-mvp>
      <card-player-mvp
        v-else-if="turnMvp && turnMvp.player && !isWorldCup"
        :featured-player-image="turnMvp.playerSeasonInfo?.playerImage?.url"
        :featured-player-name="turnMvp.player.shortName"
        :featured-player-team-image="turnMvp.playerSeasonInfo?.team?.logo?.url"
        :featured-player-stat="1"
        :turn-number="turnMvp.turnName"
        :has-partner="!!mvpPartner"
        :partner-logo="mvpPartner?.logo"
        :partner-name="mvpPartner?.name"
        :table-stat-title="$t('general.stats.mvps')"
        page-stats-link="TurnMvp"
        hide-stat
      ></card-player-mvp>
      <!--end mvp cards-->

      <card-player-ranking-general-no-partner
        v-if="!!currentSeason"
        :season-id="currentSeason.id"
        page-stats-link="GoalsGrouped"
        parameter-string="GOL"
        :pre-title-stat="$t('cards.stats.pretitle.top')"
        :title-stat="$t('cards.stats.title.scorer')"
        :table-stat-title="$t('general.stats.goals')"
      />

      <card-player-ranking-general-no-partner
        v-if="!!currentSeason"
        :season-id="currentSeason.id"
        page-stats-link="Passing"
        parameter-string="ASS-V"
        :pre-title-stat="$t('cards.stats.pretitle.top')"
        :title-stat="$t('cards.stats.title.assistant')"
        :table-stat-title="$t('general.stats.assists')"
      />

      <card-best-goalkeeper
        v-if="!!currentSeason"
        :season-id="currentSeason.id"
        page-stats-link="Goalkeeper"
        :disclaimer="isWorldCup ? $t('cards.stats.disclaimer.bestGkKWC') : undefined"
      />

      <card-player-ranking-cards v-if="!!currentSeason" :season-id="currentSeason.id" />

      <card-store-ad
        v-if="
          storeBanner &&
          storeBanner.image &&
          storeBanner.webContext.includes(isWorldCup ? 'world-cup' : webContext)
        "
        light
        :product-image="storeBanner.image"
        :product-title="storeBanner.description"
        :product-link="storeBanner.link"
        :product-link-target="storeBanner.linkTarget"
        title-card="Tienda oficial"
      ></card-store-ad>
    </div>
  </div>
</template>

<style scoped>
.main-container-cards {
  @apply grid grid-cols-1 justify-items-center sm:grid-cols-2 md:grid-cols-3 md:grid-rows-[repeat(2,minmax(0,568px))] gap-x-4 gap-y-[35px] mt-4;
}
</style>
