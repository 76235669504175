// nuxt-ui/Shared/composables/youtube.ts

import { useRoute } from "#app";
import type { YoutubeChannel } from "~/src/youtube/domain/models/YoutubeVideo";
import type { YoutubeChannelDTO } from "~/src/youtube/infrastructure/DTO/YoutubeChannelDTO";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

interface UseYoutube {
  getYoutubeChannelData: () => Promise<YoutubeChannel | void>;
}

export const useYoutube = (): UseYoutube => {
  return {
    getYoutubeChannelData,
  };

  async function getYoutubeChannelData(): Promise<YoutubeChannel | void> {
    const { params } = useRoute();
    const { webContext } = useWebContext();
    const region = params.region;
    //todo - create world-cup at backend
    const path =
      region === "world-cup"
        ? `/api/prd/youtube-videos/${webContext}/espana`
        : `/api/prd/youtube-videos/${webContext}/${region}`;

    try {
      const response = await $fetch<YoutubeChannelDTO>(path);

      if (response) {
        return response;
      } else {
        console.error("NOTE: Fetched YouTube channel info is null or undefined");
      }
    } catch (e) {
      console.error(e);
    }
  }
};
